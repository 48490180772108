import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import Link from 'gatsby-link'
import { Section, Title, Text, Box, Span } from "../../components/Core";
import Newarrow from '../../assets/image/jpeg/enter.svg'
import imgMobile from "../../assets/image/jpeg/how-laser-works.jpg";
import { device } from "../../utils";

import AcneImage from "../../assets/image/jpeg/acne.jpg";
import AcneImageTwo from "../../assets/image/jpeg/acne2.jpg";
import AcneImageThree from "../../assets/image/jpeg/acne3.jpg";


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const WrapImage = styled.img`
object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;

`;



const WrapCondition = styled.div`
position: relative;
    max-width: 100%;
    width: 100%;
    height:300px;
    background-color: #fff;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 5%)
    border-radius: 5px;
    overflow: hidden;

`;


const Conditionwrap = styled.div`
@media screen and (min-width: 1500px)
{
    padding-left: 55px;
}

@media screen and (min-width: 1400px)
{
    padding: 50px 40px 150px 45px;
}

`;


const ConditionTitle = styled.h2`
@media screen and (min-width: 1500px)
{
    font-size: 18px;
    color: rgb(171,142,102);
}

@media screen and (min-width: 1400px)
{
   font-size:16px;
}

`;





const ConditionsPage = () => {
  return (
    <>
  
        <Section py={4} bg="#f6f6f6">
        <div ></div>
          <Container  className="pt-lg-5 pb-lg-5">
         <Row>
            <Col  lg="4">
<WrapCondition className="rounded shadow">
    <Row>
        <Col className="" lg="7">
            <Conditionwrap>
<ConditionTitle>Acne</ConditionTitle>

<Text>One of the most common skin problems</Text>
</Conditionwrap>
        </Col>

        <Col lg="5">
            <WrapImage src={AcneImage}>

</WrapImage>
        </Col>
    </Row>
</WrapCondition>
            </Col>

            <Col  lg="4">
<WrapCondition className="rounded shadow">
    <Row>
        <Col className="" lg="7">
            <Conditionwrap>
<ConditionTitle>Acne Cosmetica</ConditionTitle>

<Text>Caused by cosmetic use, it is a mild form of comedonal acne</Text>
</Conditionwrap>
        </Col>

        <Col lg="5">
            <WrapImage src={AcneImageTwo}>

</WrapImage>
        </Col>
    </Row>
</WrapCondition>
            </Col>


            <Col  lg="4">
<WrapCondition className="rounded shadow">
    <Row>
        <Col className="" lg="7">
            <Conditionwrap>
<ConditionTitle>Acne Mechanica</ConditionTitle>

<Text>A kind of acne that develops when the skin is subjected to continual
pressure and friction</Text>
</Conditionwrap>
        </Col>

        <Col lg="5">
            <WrapImage src={AcneImageThree}>

</WrapImage>
        </Col>
    </Row>
</WrapCondition>
            </Col>

         </Row>
</Container>
          
        </Section>
     
    
    </>
  );
};
export default ConditionsPage;
