import React from "react";
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/index/indexcontent.js";
import Hero from "../sections/index/indexhero.js";
import Treatment from "../sections/index/indextreatments.js";
import Brand from "../sections/homepage/Brands.js";
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Testimonial from "../sections/index/indexreviews.js";
import Pop from "../sections/landing/popular";
import Ethics from "../sections/index/indexethics.js";
import Saveface from "../sections/landing/saveface";
import Clinic from '../sections/nose/nosefind.js';
import Blog from '../sections/index/indexblog.js';
import Dbanner from '../sections/index/indexbanner.js';
import Dtreat from '../sections/index/treatdekstop.js';
import Conditions from '../sections/condition/conditions.js';

const ConditionsPage = () => {
  return (
    <>

<Head title="Experts in Non Surgical Treatments London" image="/images/dermamina-home.jpg"  description="Experts in non surgical nose job Rhinoplasty and skincare treatments London" keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>

<PageWrapper headerDark>
    <Dbanner />
<Conditions />

<Testimonial />
<Clinic />  
<Pop />
<Brand />
<Saveface />
      </PageWrapper>
    </>
  );
};

export default ConditionsPage;
